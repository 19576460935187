import React, {useState} from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Carousel from "../components/carousel"
import ImageRow from "../components/image-row"
import Break from "../components/break"
import Titles from "../components/titles"
import SEO from "../components/seo"
import ContactForm from "../components/contact-form"
import styled from "styled-components"




var NextButton = styled.div`
display:none;
  @media (min-width: 1000px) {
    display:block;
width: 80px;
height:80px;
position:absolute;
right:10px;
top:48vh;
background-size:cover;
background-image:url(/arrow.svg);
}
`

var PrevButton = styled.div`
display:none;
  @media (min-width: 1000px) {
    display:block;
    width: 80px;
    height:80px;
    position:absolute;
    left:10px;
    top:48vh;
    transform:rotate(180deg);
    background-size:contain;
    background-position:center center;
    background-image:url(/arrow.svg);
}
`



var ImageThumbnail = styled.div`
display:block;
width: 60px;
height:60px;
padding:10px;
margin:10px;
boxSizing:border-box;
cursor:pointer;

border: ${props => props.current == true ? "1px solid black" : "none"};
background-size:cover;
background-image: url(${props => props.url ? props.url : 3});
}`




const exhibition_row = (artworks) => {
    let artwork_rows = artworks.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index/3)

      if(!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }

      resultArray[chunkIndex].push({
        type:"image",
        link:"/" + item.slug,
        url:item.imageThumbnail?.[0]?.medium ?? item.images?.[0]?.medium,
        details:<div><div className="title">{item.artist[0].title}</div><div className="materials">{item.title}</div><div className="dimensions">{item.materials}</div></div>
      })

      return resultArray
      }, []).map((row) => <ImageRow forceLength={3} images={row} /> )

    return artwork_rows;
}

const artwork_row = (artworks) => {
      let artwork_rows = artworks.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index/3)

    if(!resultArray[chunkIndex]) {
    resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push({
    type:"image",
    link:"/" + item.slug,
    url:item.imageThumbnail?.[0]?.medium ?? item.images?.[0]?.medium,
    details:<div><div className="title">{item.artist[0].title}</div><div className="materials">{item.title}</div><div className="dimensions">{item.materials}
    </div></div>
    })

    return resultArray
    }, []).map((row) => <ImageRow forceLength={3} images={row} /> )

    return artwork_rows;
}
const enquire = (sold) => {
    console.log(sold);
    if(sold && sold.includes("sold")) {

    return <div className={"titles inline"}>This artwork is no longer available.</div>
  }
  return <ContactForm><div className={"titles inline"}><h3>Enquire about this artwork</h3></div></ContactForm>
}

const moreWorks = (artworks, current, artist_name, exhibition,pageContext) => {
  let filtered_artworks = artworks.filter((artwork) => artwork.id != current.id);
  if(filtered_artworks  &&  filtered_artworks.length > 0) {

  return <>



       {artwork_row(filtered_artworks.slice(0, 3))}
         <div style={{textAlign:"center"}} className={"titles inline"}><Link style={{textDecoration:"none", color:"black"}} to={"/" + pageContext.artwork.artist[0].slug}><h3 style={{marginRight:"0px", marginBottom:"0px",marginTop:"20px", textAlign:"center"}}>See all artworks by {pageContext.artwork.artist[0].title}</h3></Link></div>
       </>
   }
}

const backtoExhibition = (exhibition) => {
  if(exhibition && exhibition.slug && new Date(exhibition.endDate) > new Date()) {
      return <Link to={"/" + exhibition.slug}><h3>Back to exhibition</h3></Link>
  }
}
const backToArtist = (pageContext) => {
  if(pageContext.artwork.artist[0].artistStatus == "independentArtist") {
    return  <h3 style={{marginRight:"0px", marginBottom:"20px",marginTop:"10px", textAlign:"center"}}>{pageContext.artwork.artist[0].title}</h3>
  }
  else {
  return <Link style={{textDecoration:"none", color:"black"}} to={"/" + pageContext.artwork.artist[0].slug}>
    <h3 style={{marginRight:"0px", marginBottom:"20px",marginTop:"10px", textAlign:"center"}}>{pageContext.artwork.artist[0].title}</h3>
  </Link>
  }
}
const price = (price,showPrice,sold) => {

  if(showPrice && !sold[0]) {

    return <ImageRow  images={[{type:"text", text: <div><p style={{padding:"0px 20px 0px 20px", textAlign: "center", fontSize:"18px",   whiteSpace: "pre-wrap" }}>{price}</p></div>, span:"12"}] } />
  }
}

const marketplace = (marketplace) => {

    if(marketplace && marketplace.length > 0) {

        return <ImageRow  images={[{type:"text", text: <div><p style={{padding:"0px 20px 0px 20px", textAlign: "center", fontSize:"18px",   whiteSpace: "pre-wrap" }}>Note - this is a second-hand work for sale through the Frances Keevil marketplace</p></div>, span:"12"}] } />
    }
}
const nextAndPreviousButtons = (artwork, exhibition_page, exhibition) => {
  var allArtworks;
  if(exhibition_page) {
    allArtworks = exhibition.artworks
  }
  else {
    allArtworks = artwork.artist?.[0]?.allArtworks
  }

  const isStandardArtwork = allArtworks.some((a) => artwork.slug == a.slug);
  if(isStandardArtwork) {
    let getIdx = allArtworks.map((a) => a.id).indexOf(artwork.id);
    let prevLink = <div></div>;
    let nextLink = <div></div>;
    if(getIdx > 0) {
    if(exhibition_page) {
      prevLink =<Link to={`/${allArtworks[getIdx - 1].slug}/exhibition`}><PrevButton /></Link>
    }
    else {
      prevLink =<Link to={`/${allArtworks[getIdx - 1].slug}`}><PrevButton /></Link>
    }

    }
    if(getIdx < allArtworks.length - 1) {
      if(exhibition_page) {
        nextLink = <Link to={`/${allArtworks[getIdx + 1].slug}/exhibition`}><NextButton /></Link>
      }
      else {
        nextLink = <Link to={`/${allArtworks[getIdx + 1].slug}`}><NextButton /></Link>
      }
    }

    return <div><div>{prevLink}</div><div>{nextLink}</div></div>
  }

}
const thumbnails = (images,setCurrentImage,currentImage) => {
  if(images.length > 1) {
    return <div style={{display:"flex", alignItems: "center", justifyContent: "center"}}>{images.map((image,idx) => <ImageThumbnail current={idx == currentImage} url={image.medium} onClick={() => setCurrentImage(idx)}/>)}</div>
  }
}
const artSingleImage = (image) => {
  return <ImageRow images={[{size:"full", url:image.medium, details:<div></div>}]} />
}
const artImages = (images,currentImage, setCurrentImage) => {
  return <div>

    {<ImageRow images={[{size:"full", url:images[currentImage].medium, details:<div></div>}]} />}
    {thumbnails(images,setCurrentImage,currentImage)}
    </div>

}

const IndexPage = ({pageContext}) => {

    const [currentImage, setCurrentImage] = useState(0);

    if(pageContext.artwork && pageContext.artwork.images && pageContext.artwork.images[0] &&  pageContext.artwork.artist && pageContext.artwork.artist[0]) {
      let showcase_artwork = pageContext.artwork.artist[0].showcaseArtworks.some((artwork) => artwork.id == pageContext.artwork.id);
      return (<div>
        <Layout>
          <SEO title={pageContext.artwork.title} />
          <div style={{marginTop:"70px" }}>
          <div style={{textAlign:"center"}} className={"titles inline"}>{backtoExhibition(pageContext.exhibition)}{backToArtist(pageContext)}</div>
            {nextAndPreviousButtons(pageContext.artwork, pageContext.exhibition_page, pageContext.exhibition)}
            {artImages(pageContext.artwork.images,currentImage, setCurrentImage)}

            <div><h1 style={{textAlign:"center", fontSize:"36px", marginTop:"80px",marginBottom:"40px"}}>{pageContext.artwork.title}</h1></div>
            <ImageRow  images={[{type:"text", text: <div><p style={{padding:"0px 20px 0px 20px", textAlign: "center", fontSize:"18px",   whiteSpace: "pre-wrap" }}>{pageContext.artwork.materials}</p></div>, span:"12"}] } />

            {price(pageContext.artwork.price,pageContext.artwork.showPrice?.[0],pageContext.artwork.sold)}
              {marketplace(pageContext.artwork.marketplace)}

            {enquire(pageContext.artwork.sold)}
            <ImageRow  images={[{type:"text", text: <div><p style={{padding:"0px 20px 0px 20px", textAlign: "center", fontSize:"18px",   whiteSpace: "pre-wrap" }}>{pageContext.artwork.pageText}</p></div>, span:"12"}] } />
            {moreWorks(pageContext.artwork.artist[0].artworks, pageContext.artwork, pageContext.artwork.artist[0].title, pageContext.exhibition,pageContext)}
          </div>
        </Layout>
      </div>
      )

  }
  return <div></div>
}


export default IndexPage
